/* React Flow Control Styles */
.react-flow__controls-button {
  background-color: #f0f0f0 !important; /* Softer background color */
  color: #333 !important;               /* Darker font color for better contrast */
  border-radius: 50%;                   /* Circular buttons */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Soft shadow */
  padding: 6px;                         /* Smaller padding */
  font-size: 12px;                      /* Smaller font size */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.react-flow__controls-button:hover {
  background-color: #e0e0e0 !important; /* Slightly darker on hover */
  transform: scale(1.1);
}

.react-flow__controls {
  background-color: #ffffff !important; /* Pure white background */
  border-radius: 8px;                   /* Rounded edges for the control panel */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 15px;  /* Reduced padding */
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* More prominent shadow */
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Reduced gap */
}

.modal input {
  padding: 8px; /* Smaller padding */
  font-size: 14px; /* Smaller font size */
}

/* Common Button Styles */
.button {
  margin-bottom: 10px;
  border: none;
  padding: 5px 10px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15); /* Soft shadow */
  color: #333;
}

/* Add Buttons (Green) */
.button-add {
  background-color: #ffffff; /* Green */
}

.button-add:hover {
  background-color: #45a049; /* Darker Green */
  transform: scale(1.05);
  color:#fff;
}

/* Set Parameters Button (Gray) */
.button-set {
  background-color: #757575; /* Gray */
  color: white;
}

.button-set:hover {
  background-color: #616161; /* Darker Gray */
  transform: scale(1.05);
}

/* Save Button (Orange) */
.button-save {
  background-color: #555; /* Orange */
  color: white;
}

.button-save:hover {
  background-color: #FB8C00; /* Darker Orange */
  transform: scale(1.05);
}

/* Deploy Button (Red) */
.button-deploy {
  background-color: #333; /* Red */
  color: white;
}

.button-deploy:hover {
  background-color: #D32F2F; /* Darker Red */
  transform: scale(1.05);
}

/* File Input Styling */
.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.button-add.file-upload {
  position: relative;
  overflow: hidden;
  background-color: #3B5998; /* Facebook Blue for differentiation */
}

.button-add.file-upload:hover {
  background-color: #334F88;
  transform: scale(1.05);
}

.button.button-addrow{
  display:inline;
  align-content:center;
  background-color:transparent;
  border:none;
  color:#333;
box-shadow:none;
}
.button.button-addrow:hover{
  opacity:0.5;
}

/* Input Field Styling */
.input {
  padding: 10px;
  font-size: 14px; /* Smaller font size */
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 8px;
  width: 80%; /* Adjust width as needed */
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #777;
  outline: none;
}

/* Node Styling */
.node {
  padding: 8px; /* Smaller padding */
  font-size: 14px; /* Smaller font size */
  border-radius: 5px;
}

/* EditNodeModal.css */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: #f4f4f4;
  color:#333;
  padding: 30px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  width: 400px;
}

.modal-header {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  border-radius: 0px;
}

.button:hover {
 opacity:0.8;
 transform: scale(1.05);
}

.button-delete {
  background-color: transparent;
  position:absolute;
  color:#999;
  padding:3px; width:100%;
  bottom:-10px; right:0;
}
.button-delete:hover {
  background-color: red;
  color:#fff;
}

/* styles.css */

table {
  border-spacing: 0;
  border: none;
  color:#333;
  width:100%;
  text-align:left;
  font-size:12px;
}

tr:last-child td {
  border-bottom: 0;
}

th, td {
  margin: 0;
  padding: 0.5rem;

}

th:last-child, td:last-child {
  border-right: 0;
}

td:first-of-type {
  min-width: 20ch;
}

.dragging-row {
  background: rgba(127, 207, 250, 0.3);
}
.placeholder-row {
  background: rgba(56, 124, 160, 0.3);
}
.table-data {
  background: white;
}

.drag-handle {
  height: 1rem;
  vertical-align: bottom;
  display: inline-block;
  margin-right: 0.5rem;
  cursor: grab;
}

.drag-handle.grabbing {
  cursor: grabbing;
}

.static-table-row {
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
              rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #3e1eb3 solid 1px;
}
