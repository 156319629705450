.function-name{
  text-align:left;
}
.input-container {
  margin-bottom: 10px;
  max-width:250px; width:250px;
  display:inline-block;
  vertical-align:top;
  margin:15px; 

}


.chain-selection-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow:auto;
  max-height:80vh;
  border-radius: 5px;
}
.chainnotice{
  text-align:left;
  padding:15px;
}
.library.null, .input-container.null {
  display:none;
}

.chain-selection-list.null{
  cursor:none;

}

.chain-selection-list.null .chain-item{
  cursor:default;
  background:none !important;
  color:#000;
}
.chain-selection-list.null .chain-item *{
filter:grayscale(100%);
}

.chain-item {
  padding: 10px;
  cursor: pointer;
}

.chain-item span {
  padding-left: 10px;
  vertical-align:super;
}
.chain-item:hover {
  background-color: #f0f0f0;
}

.chain-item.selected {
  background-color: #003a80;
  color: white;
}

.editor-container {
  display: flex;
  background-Color: #f5f5f5;
  font-Size: 12px;
  min-height:60vh;
}
.editor-container.null {
display:none;
}
.editor {
 width:100%;
 background:#F6F8FA;
}



.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}



.button-container button:hover {
  background-color: #003a80;
}

.button-container .button-delete:hover {
  background-color: #da1414;
}
.function-editor{
  animation: slide-in 0.3s ease;
}

